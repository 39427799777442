<template>
  <v-dialog
    v-model="campaignDialog"
    scrollable
    hide-overlay
    max-width="1000"
    transition="dialog-bottom-transition"
  >
    <v-card
      style="padding-bottom: 20px;"
    >
      <v-toolbar
        color="primary"
        dark
      >
        <v-col
          cols="11.5"
        >
          <span
            class="headline"
          >{{ campaign.nome }}</span>
        </v-col>
        <v-btn
          icon
          dark
          @click="cancel"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <base-loading v-if="campaignDialogLoading" />
      <v-card-text
        v-else
        style="padding: 3px;"
      >
        <v-list-item>
          <v-list-item-avatar
            height="450"
            width="350"
            tile
            color="grey"
          >
            <v-img
              :src="campaign.imagem"
              contain
            />
          </v-list-item-avatar>

          <v-list-item-content class="align-self-start">
            <div
              style="font-size: 14px; color: #808080; text-align: justify; max-width: 585px;"
              class="ml-2 mb-1 mr-2"
            >
              <span><b>{{ campaign.descricao }}</b></span>
            </div>
            <div
              style="font-size: 14px; color: #808080; align-self: center;"
              class="ml-2 mb-1 text-subtitle-1"
            >
              <span style="color: #143693; font-weight: bold;">Período de vigência: </span>
              <span>{{ formatData(campaign.data_inicio) }} á {{ formatData(campaign.data_fim) }}</span>
              <br>
              <span style="color: #143693; font-weight: bold;">Previsão de Entrega: </span>
              <span> {{ campaign.entrega }} dias</span>
            </div>
            <div
              style="font-size: 14px; color: #808080; text-align: justify;"
              class="ml-2 mb-2"
            >
              <span style="color: #143693; font-weight: bold;">Regras: </span>
            </div>
            <div
              class="container mx-0 pt-1"
              style="overflow-y: auto; height:325px"
            >
              <v-row
                v-for="( camp, i ) in campanhaRegras"
                :key="i"
                class="ml-1"
              >
                <v-col
                  cols="12"
                  class="mb-2 py-0"
                />
                <v-card
                  elevation="1"
                  style="padding-left: 15px;  min-height: 65px; width: 560px; background-color: #f5f5f5; border-radius: 18px;"
                >
                  <v-row
                    class="mx-0 my-0"
                  >
                    <v-col
                      class="pt-1"
                      cols="8"
                      sm="9"
                      md="9"
                      lg="9"
                      xl="9"
                    >
                      <div>
                        <v-row
                          class="my-0 px-0"
                        >
                          <p
                            style="font-size: 14px; color: #143693; font-weight: bold;"
                            class="mt-1 mb-1 pl-0"
                            color="primary"
                          >
                            <b>{{ camp.nome }}</b>
                          </p>
                        </v-row>
                        <v-row
                          class="my-0 px-0"
                          align="center"
                        >
                          <v-col
                            class="px-0 py-0 mx-0 my-0"
                            cols="12"
                          >
                            <p
                              style="font-size: 12px"
                              class="mb-0 pb-0 my-0"
                            >
                              <b>Desconto de {{ camp.desconto }}% na compra de {{ camp.qtd }}{{ camp.tipo === 'quantidade' ? ' unidades': ' volumes.' }}</b>
                            </p>
                            <span
                              style="font-size: 12px"
                              class="mt-2 py-0 my-0"
                            >
                              Podendo ser parcelado em até {{ camp.parcelas }}x.
                            </span>
                          </v-col>
                        </v-row>
                      </div>
                    </v-col>
                  </v-row>
                </v-card>
              </v-row>
            </div>
          </v-list-item-content>
        </v-list-item>
      </v-card-text>
      <v-card-actions
        style="padding-top: 15px;"
      >
        <v-row
          align="center"
          justify="space-around"
        >
          <v-btn
            class="text-none text-white"
            color="primary"
            center
            rounded
            @click="cancel"
          >
            <v-icon
              dark
              left
            >
              mdi-minus-circle
            </v-icon>
            Fechar
          </v-btn>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
  import { mapActions, mapGetters, mapState } from 'vuex'
  import { CLOSE_CAMPAIGN_DIALOG } from '@/store/modules/loja'

  export default {
    name: 'CampaignDialog',
    data: () => ({
    }),
    computed: {
      ...mapState('loja', ['campaignDialog', 'campaignDialogLoading', 'campaign']),
      ...mapGetters('loja', ['catalogoFiltered']),
      campanhaRegras () {
        const xRegras = []
        this.campaign.regras.forEach(regra => {
          regra.condicoes.forEach(condicao => {
            xRegras.push({ nome: regra.nome, tipo: regra.tipo, qtd: (regra.tipo === 'quantidade' ? condicao.quantidade : condicao.volume), desconto: condicao.desconto, parcelas: condicao.parcelamento, unidade: condicao.unidade })
          })
        })
        return xRegras
      },
    },
    mounted () {
      document.activeElement.blur()
    },
    methods: {
      ...mapActions('loja', [CLOSE_CAMPAIGN_DIALOG]),
      cancel () {
        this.CLOSE_CAMPAIGN_DIALOG()
      },
      formatData (date) {
        if (!date) {
          return ''
        }
        const data = new Date(date)
        data.setTime(data.getTime() + data.getTimezoneOffset() * 60 * 1000)
        return (this.adicionaZero(data.getDate()) + '/' + this.adicionaZero(data.getMonth() + 1) + '/' + data.getFullYear())
      },
      adicionaZero (numero) {
        if (numero <= 9) {
          return '0' + numero
        } else {
          return numero
        }
      },
    },
  }
</script>
<style lang="scss" scoped>

.card-scroll-container {
  overflow: auto
}

.v-card__text {
  flex-grow: 1;
  overflow: auto;
}

</style>
