<template>
  <v-card
    class="mx-1 my-0"
    width="100%"
    min-width="240"
    style="cursor: pointer"
  >
    <div>
      <div
        v-if="(produto.desconto && produto.id_campanha === '-1' && produto.nomeCampanha === '') && ((this.$store.state.user.roles[0].slug === 'produtor' && produto.disponibilidade !== 2) || (this.$store.state.user.roles[0].slug === 'tecnico' && produto.preco > 0))"
        style="
        position: absolute;
        z-index: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 61px;
        height: 61px;
        background-color: #E40E0E;
        border-bottom-right-radius: 200px;
        border-top-left-radius: 30px;
        "
      >
        <p
          style="line-height: 104%; transform: rotate(-45deg); margin-left: 8px; color:white;z-index: 1; font-weight: bold; width: 50px; font-size: 18px;"
        >
          {{ produto.desconto }}% Off
        </p>
      </div>
      <div
        v-else-if="( this.$store.state.user.roles[0].slug === 'produtor' && produto.disponibilidade === 2 ) || produto.preco <= 0"
        style="
        position: absolute;
        top: 0;
        z-index: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 61px;
        height: 61px;
        background-color: #ff8f00;
        border-bottom-right-radius: 200px;
        border-top-left-radius: 30px;
          "
      >
        <p
          class="mt-3"
          style="color:white;z-index: 1;transform: rotate(-45deg);font-weight: bold; font-size: 16px;"
        >
          Cotação
        </p>
      </div>
      <div
        :style="produto.nomeCampanha ? 'position: relative; overflow: hidden; left: 0px; top: 0px; right: 0px; border-bottom-right-radius: 200px; z-index: 2; background: #2DADED; width: 70%; height: 45px; padding: 0px; border-top-left-radius: 4px; border-top-right-radius: 4px; font-size: 13px !important;' :
          'position: relative; overflow: hidden; left: 0px; top: 0px; right: 0px; z-index: 2; display: flex; padding: 0px; border-top-left-radius: 4px; border-top-right-radius: 4px; font-size: 13px !important; height: 45px'"
      >
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <p
              v-if="produto.nomeCampanha"
              style="color:white; margin: 3px auto; text-align: left; font-weight: bold; max-width: 90%;"
              v-bind="attrs"
              v-on="on"
              @click="handleCampanhaClick(produto)"
            >
              <v-icon
                small
                color="white"
                class="mr-1"
              >
                mdi-tag
              </v-icon>
              {{ produto.nomeCampanha }}
            </p>
          </template>
          <span>Clique aqui para acessar os detalhes da campanha</span>
        </v-tooltip>
      </div>
      <v-img
        style="margin-top: 10px; margin-bottom: 10px"
        height="140"
        min-height="100"
        max-width="100%"
        contain
        :src="showImage"
        @click="handleProdLink(produto.id)"
      />
    </div>

    <div>
      <div style="height: 65px;">
        <v-card-title
          style="font-size: 18px; color: #143693; font-weight: bold;"
          @click="handleProdLink(produto.id)"
        >
          {{ produto.nome }}
        </v-card-title>
      </div>
      <v-card-text
        class="grow pb-0 pt-0"
        style="height: 70px;"
        @click="handleProdLink(produto.id)"
      >
        <div
          style="font-size: 16px; color: #808080;"
          class="mb-0"
        >
          {{ produto.marca }}
        </div>
        <div
          v-if="produto.entrega"
          style="font-size: 16px; color: #808080;"
          class="mb-0"
        >
          Previsão de Entrega: {{ produto.entrega }} dias
        </div>
        <div
          v-if="produto.pedido_min"
          style="font-size: 16px; color: #808080;"
          class="mb-0"
        >
          Mínimo de {{ produto.pedido_min }} unidade(s)
        </div>
      </v-card-text>

      <v-card-text
        style="height: 28px;"
        class="pb-0 pt-0"
      >
        <div
          v-if="(produto.desconto && produto.id_campanha === '-1' && produto.nomeCampanha === '' && produto.preco > 0) || produto.nomeCampanha"
          style="font-size: 14px; font-weight: 700; color: #143693;"
        >
          Período: {{ moment(produto.data_inicio).format('DD/MM/YYYY') }} a {{ moment(produto.data_fim).format('DD/MM/YYYY') }}
        </div>
      </v-card-text>
    </div>

    <v-card-title
      @click="handleProdLink(produto.id)"
    >
      <div
        v-if="(this.$store.state.user.roles[0].slug === 'tecnico' && produto.preco > 0) || (produto.disponibilidade !== 2)"
        style="width: 100%; height: 95px; padding: 10px;"
      >
        <v-row>
          <v-col
            cols="12"
            class="pa-0 ma-0"
          >
            <div style="min-height: 30px; margin-bottom: -5px;">
              <span
                v-if="produto.desconto && !produto.nome_campanha"
                class="oferta"
              >
                R$ {{ currencyFormatter( produto.preco ) }}
              </span>
            </div>
            <div>
              <span
                class="preco"
              >
                R$ {{ produto.desconto > 0 && !produto.nome_campanha ? currencyFormatter( (parseFloat(produto.preco) - (parseFloat(produto.preco) * (parseFloat(produto.desconto) / 100))) ) : currencyFormatter( produto.preco ) }}
              </span>
            </div>
          </v-col>
          <v-col
            cols="12"
            class="pa-0 ma-0"
          >
            <v-btn
              style="
              font-size: 20px;
              border-radius: 0px 0px 4px 4px;
              position: absolute;
              left: 0px;
              bottom: 0px;
              width: 100%;"
              color="success"
              elevation="0"
              class="text-none"
            >
              Comprar
            </v-btn>
          </v-col>
        </v-row>
      </div>
      <div
        v-else
        style="width: 100%; height: 95px; padding: 10px;"
      >
        <v-row>
          <v-col
            cols="12"
            class="pa-0 ma-0"
          >
            <div
              v-if="(this.$store.state.user.roles[0].slug === 'produtor' && produto.disponibilidade === 2) || produto.preco <= 0"
              style="font-size: 16px; font-weight: bold; color: red; min-height: 30px; margin-bottom: -5px; white-space: normal; overflow-wrap: break-word;"
            >
              <p>Preço disponível somente por cotação</p>
            </div>

            <div style="min-height: 35px; margin-bottom: -10px; margin-left: 5px;" />
          </v-col>
          <v-col
            cols="12"
            class="pa-0 ma-0"
            style="display: flex; justify-content: end;"
          >
            <v-btn
              color="warning"
              elevation="0"
              class="text-none"
              style="
              font-size: 20px;
              border-radius: 0px 0px 4px 4px;
              position: absolute;
              left: 0px;
              bottom: 0px;
              width: 100%;"
            >
              Cotar
            </v-btn>
          </v-col>
        </v-row>
      </div>
    </v-card-title>
  </v-card>
</template>

<script>
  import { formatDescText, handleProdLink, formatImageLink } from './helpers'
  import { ADD_TO_CART } from '@/store/modules/carrinho'
  import { mapState, mapActions } from 'vuex'
  import { currencyFormatter } from '../../utils/formatter'
  import { OPEN_CAMPAIGN_DIALOG } from '@/store/modules/loja'

  export default {
    props: {
      produto: {
        type: Object,
        default: () => (
          {
            id: 'fa6d6298-60bf-4509-9dbb-79d1c51a3d90',
            nome: 'Excede (CCFA)',
            marca: 'Zoetis',
            descricao: 'EXCEDE (CCFA) Suspensão Estéril – 200 mg/mL é uma formulação pronta para uso que contém ácido livre cristalino de ceftiofur, um antibiótico com largo espectro de ação, pertencente ao grupo das cefalosporinas, ativo contra bactérias Gram positivas e Gram negativas, incluindo cepas produtoras de β-lactamase. Como outras cefalosporinas, ceftiofur é bactericida in vitro, devido a sua ação de inibição da síntese da parede bacteriana.',
            imagens: ['24b6b33a-3d1f-409e-939a-ab81bed0929f.0.png'],
          }
        ),
      },
    },
    data () {
      return {
        descLength: 50,
        currencyFormatter,
      }
    },
    computed: {
      ...mapState('carrinho', ['produtos']),
      ...mapState('loja', ['campaignDialog']),
      showImage () {
        return this.produto.imagens[0] ? this.formatImageLink(this.produto.imagens[0]) : 'img/prodImage.png'
      },
    },
    methods: {
      ...mapActions('carrinho', [ADD_TO_CART]),
      ...mapActions('loja', [OPEN_CAMPAIGN_DIALOG]),
      formatDescText,
      handleProdLink,
      formatImageLink,
      encontrei_Produto (produto) {
        return (!(this.produtos.find(el => el.id === produto.id) === undefined))
      },
      handleCampanhaClick (produto) {
        this.OPEN_CAMPAIGN_DIALOG(produto.id_campanha)
      },
      checa_Produto (produto) {
        return (!(this.produtos.find(el => el.id === produto.id) === undefined))
      },
      muda_botao (produto) {
        if (this.encontrei_Produto(produto)) {
          window.scrollTo({
            top: 0,
            behavior: 'smooth',
          })
          this.$router.push({ path: `/${this.$user.get().role}/loja/carrinho` })
        } else {
          this.ADD_TO_CART(produto)
          this.handleProdLink(produto.id)
        }
      },
    },
  }
</script>

<style scoped>
.oferta {
    font-size: 20px;
    text-decoration: line-through;
    text-align: left;
    margin: 0px;
    color: #808080;
}

.preco {
  font-size: 24px;
  text-align: left;
  color: #373737;
}
</style>
